// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-account-jsx": () => import("./../../../src/pages/account.jsx" /* webpackChunkName: "component---src-pages-account-jsx" */),
  "component---src-pages-all-rewards-jsx": () => import("./../../../src/pages/all-rewards.jsx" /* webpackChunkName: "component---src-pages-all-rewards-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-cookies-jsx": () => import("./../../../src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-error-jsx": () => import("./../../../src/pages/error.jsx" /* webpackChunkName: "component---src-pages-error-jsx" */),
  "component---src-pages-event-promotions-jsx": () => import("./../../../src/pages/event-promotions.jsx" /* webpackChunkName: "component---src-pages-event-promotions-jsx" */),
  "component---src-pages-home-jsx": () => import("./../../../src/pages/home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-fetch-jsx": () => import("./../../../src/pages/login-fetch.jsx" /* webpackChunkName: "component---src-pages-login-fetch-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-map-preview-jsx": () => import("./../../../src/pages/map-preview.jsx" /* webpackChunkName: "component---src-pages-map-preview-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-problem-jsx": () => import("./../../../src/pages/problem.jsx" /* webpackChunkName: "component---src-pages-problem-jsx" */),
  "component---src-pages-qr-rewards-fetch-jsx": () => import("./../../../src/pages/qr-rewards-fetch.jsx" /* webpackChunkName: "component---src-pages-qr-rewards-fetch-jsx" */),
  "component---src-pages-qr-rewards-jsx": () => import("./../../../src/pages/qr-rewards.jsx" /* webpackChunkName: "component---src-pages-qr-rewards-jsx" */),
  "component---src-pages-registration-jsx": () => import("./../../../src/pages/registration.jsx" /* webpackChunkName: "component---src-pages-registration-jsx" */),
  "component---src-pages-reward-lander-jsx": () => import("./../../../src/pages/reward-lander.jsx" /* webpackChunkName: "component---src-pages-reward-lander-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

